import userflow from 'userflow.js'
import { User } from '../types/globals';

// Docs: https://docs.userflow.com/docs/userflow-js#userflow-js-reference

userflow.init(process.env.REACT_APP_USERFLOW_TOKEN)

async function identifyUser(user: User){
  userflow.identify(
    user.b2cObjectId,
    {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email
    }
  );
}

export { identifyUser }